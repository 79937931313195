import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { LinkButton, LogoCircle, Seo, Typography } from '../components/utils'

export default function NotFoundPage() {
  const [windowPathname, setWindowPathname] = useState('')

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.pathname) {
      setWindowPathname(window.location.pathname)
    }
  }, [])
  return (
    <>
      <Seo title={`404: Die Seite '${windowPathname}' wurde nicht gefunden`} />
      <Conatiner>
        <Logo />
        <Typography variant="h1">404</Typography>
        <Typography variant="h4">Das muss jz echt nicht sein</Typography>
        <Typography>
          Leider gibt es die Seite <i>&nbsp;{windowPathname}&nbsp;</i> nicht.
        </Typography>
        <LinkButton to="/">Zurück zur Startseite</LinkButton>
      </Conatiner>
    </>
  )
}

const Conatiner = styled.section`
  padding: 0;
  margin: 0;
  min-height: 100vh;
  height: 100%;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 1fr;
  align-content: center;
  justify-items: center;
  row-gap: 1rem;
  text-align: center;
`
const Logo = styled(LogoCircle)`
  height: 10rem;
  width: 10rem;
`
